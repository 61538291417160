export default {

    methods: {

        app__validatePhone(telephone) {
            const polRegex = /^[0-9]{9}$/;
            const intRegex = /^(\+|00)[0-9]{8,15}$/;
            const phoneTrimmed = telephone.replace(/\s|\(|\)|-/g, "");

            return polRegex.test(phoneTrimmed) || intRegex.test(phoneTrimmed);
        },
        
        app__validateEmail(email) {
            const regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i; // eslint-disable-line
            return regex.test(email);
        },

    },

};
