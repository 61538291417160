<template>
    <div class="view-register">
        <viewWrapper>
            <template #header>
                <textNav @click="$router.back()">Wróć</textNav>
            </template>
            <template #content>
                <splitedView>
                    <template #left>
                        <visitSummary />
                    </template>
                    <template #right>
                        <contentBox>
                            <template #header>
                                <img src="~@/assets/icons/login-ico.svg" />
                                <div class="register-header">
                                    <h2>Podaj dane pacjenta</h2>
                                    <p>Aby zarejestrować się na wizytę, musisz je podać placówce oraz założyć konto w portalu MyDr.pl</p>
                                </div>
                            </template>
                            <template #content>
                                <div class="register-content">
                                    <div class="register-content__left">

                                        <div class="input-row" :class="{'input-row--error': $v.patient.name.$error}">
                                            <div class="input-row__input">
                                                <label>Imię pacjenta*</label>
                                                <input type="text" class="utils-input" v-model="patient.name"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.name.required">To pole jest wymagane.</small>
                                            </div>
                                        </div>

                                        <div class="input-row" :class="{'input-row--error': $v.patient.surname.$error}">
                                            <div class="input-row__input">
                                                <label>Nazwisko pacjenta*</label>
                                                <input type="text" class="utils-input" v-model="patient.surname"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.surname.required">To pole jest wymagane.</small>
                                            </div>
                                        </div>

                                        <div class="input-row" :class="{'input-row--error': $v.patient.telephone.$error}">
                                            <div class="input-row__input">
                                                <label>Telefon*</label>
                                                <input type="text" class="utils-input" v-model="patient.telephone"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.telephone.required">To pole jest wymagane.</small>
                                                <small v-if="!$v.patient.telephone.isValid">Podany numer jest niepoprawny.</small>
                                            </div>
                                        </div>

                                        <div class="input-row" :class="{'input-row--error': $v.patient.email.$error}">
                                            <div class="input-row__input">
                                                <label>E-mail*</label>
                                                <input type="text" class="utils-input" v-model="patient.email"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.email.required">To pole jest wymagane.</small>
                                                <small v-if="!$v.patient.email.email">Podany email jest niepoprawny.</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="register-content__right">

                                        <div class="input-row" :class="{'input-row--error': $v.patient.pesel.$error}">
                                            <div class="input-row__input">
                                                <label>PESEL</label>
                                                <input type="text" class="utils-input" v-model="patient.pesel"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.pesel.isValidPeselOrEmpty">Podany pesel jest niepoprawny.</small>
                                                <small v-if="!$v.patient.pesel.maxLength">Podany pesel jest za długi.</small>
                                                <small v-if="!$v.patient.pesel.minLength">Podany pesel jest za krótki.</small>
                                            </div>
                                        </div>

                                        <div class="input-row input-row--password" :class="{'input-row--error': $v.patient.password1.$error}">
                                            <div class="input-row__input">
                                                <label>*</label>
                                                <img src="~@/assets/icons/password.svg" />
                                                <input type="password" class="utils-input" placeholder="Hasło" v-model="patient.password1"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.password1.required">To pole jest wymagane.</small>
                                                <small v-if="!$v.patient.password1.minLength">
                                                    To hasło jest za krótkie. Musi zawierać co najmniej {{$config.RESTRICTIONS.MIN_PASSWORD_LENGTH}} znaków.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-row input-row--password" :class="{'input-row--error': $v.patient.password2.$error}">
                                            <div class="input-row__input">
                                                <label>*</label>
                                                <img src="~@/assets/icons/password.svg" />
                                                <input type="password" class="utils-input" placeholder="Powtórz hasło" v-model="patient.password2"/>
                                            </div>
                                            <div class="input-row__validations">
                                                <small v-if="!$v.patient.password2.required">To pole jest wymagane.</small>
                                                <small v-if="!$v.patient.password2.minLength">
                                                    To hasło jest za krótkie. Musi zawierać co najmniej {{$config.RESTRICTIONS.MIN_PASSWORD_LENGTH}} znaków.
                                                </small>
                                                <small v-if="!$v.patient.password2.sameAsPassword">
                                                    Hasła nie są zgodne.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-row input-row--info">
                                            <p>*dane wymagane</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="register-content">
                                    <div v-if="$v.agreements.$error" class="input-row input-row--info" :class="{'input-row--error': $v.agreements.$error}">
                                        <small v-if="!$v.agreements.rules.checked">
                                            Wymagana akceptacja regulaminu
                                        </small>
                                        <small v-if="!$v.agreements.consent.checked  || !$v.agreements.data_confirmation.checked">
                                            Zaakceptuj wymagane zgody
                                        </small>
                                    </div>
                                </div>
                                <div class="register-processing">
                                    <label>
                                        <input class="checkbox-blue" type="checkbox" v-model="agreements.rules"/>
                                        Akceptuję warunki i zasady <a :href="appHost+'static/Regulamin.pdf'"
                                                                      target="_blank" rel="noopener noreferrer">
                                      Regulaminu</a> oraz <a @click.prevent="rodoInfoURL" target="_blank" rel="noopener noreferrer">Informację o danych osobowych</a> *
                                    </label>
                                    <div>
                                        <label>
                                            <input class="checkbox-blue" type="checkbox" v-model="agreements.consent"/>
                                            Oświadczam, że wyrażam zgodę na przetwarzanie moich wyżej podanych danych
                                            osobowych przez {{ facilityData }}
                                            w celach niezbędnych do ...
                                            *
                                        </label>
                                        <a @click="openAgreementModal()">[Dowiedz się więcej]</a>
                                    </div>
                                    <label>
                                        <input class="checkbox-blue" type="checkbox" v-model="agreements.data_confirmation"/>
                                        Oświadczam, że wszystkie wyżej podane przeze mnie informacje są prawdziwe i biorę pełną osobistą
                                        odpowiedzialność za wszystkie skutki podania tych informacji, zwalniając w tym zakresie z wszelkiej
                                        odpowiedzialności ich adresata.*
                                    </label>
                                </div>
                            </template>
                            <template #footer>
                                <btn type="login">
                                    <img src="~@/assets/icons/button-mydr.svg" />
                                    <div class="btn-content" @click="showLogin()">
                                        <p>Jeśli masz już konto</p>
                                        <h4>zaloguj się</h4>
                                    </div>
                                </btn>
                                <btn v-if="selectedService" type="next" @click="register()">KONTYNUUJ</btn>
                                <btn v-else type="next" @click="register()">POTWIERDŹ WIZYTĘ</btn>
                            </template>
                        </contentBox>
                    </template>
                </splitedView>
            </template>
        </viewWrapper>

        <modal name="facility-agreement">
            <template #content>
                <p>Oświadczam, że wyrażam zgodę na przetwarzanie moich wyżej podanych danych osobowych przez {{ facilityData }}
                  w celach niezbędnych do zarejestrowania, umówienia, odwołania wizyty lekarskiej oraz przekazywania drogą
                  telefoniczną na wskazany numer telefonu i pocztą elektroniczną na wskazany adres, wszelkich niezbędnych
                  informacji w tym zakresie, w związku z przewidywanym skorzystaniem z usług opieki medycznej. Oświadczam, że
                  podane przeze mnie dane są zgodne z prawdą, podaję je dobrowolnie mając pełną świadomość znaczenia wyrażanej
                  zgody i wiem, że mam prawo żądania uzupełnienia, uaktualnienia, sprostowania, zmiany danych osobowych
                  zgodnie z przepisami ustawy z dnia 10 maja 2018r. o ochronie danych osobowych oraz Rozporządzenia Parlamentu
                  Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z
                  przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
                  95/46/WE (ogólne rozporządzenie o ochronie danych), a także prawo wniesienia skargi do organu nadzorczego
                  zajmującego się ochroną danych osobowych, tj. Prezesa Urzędu Ochrony Danych Osobowych.</p>
            </template>
        </modal>

        <modal
        name="login"
        :steps="true"
        @action="$event === 'confirm' ? $forms.validate('login') : false;">
            <template #content="{confirm}">
                <loginForm :step="loginStep" @logged="confirm($event)"/>
            </template>
        </modal>

    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, helpers, sameAs, maxLength, minLength } from 'vuelidate/lib/validators';

import validations from '@/mixins/local/validations';

import viewWrapper from '@/components/layout/viewWrapper/viewWrapper.vue';
import splitedView from '@/components/layout/splitedView/splitedView.vue';
import visitSummary from '@/components/functional/visitSummary/visitSummary.vue';
import loginForm from '@/components/functional/loginForm/loginForm.vue';
import contentBox from '@/components/ui/contentBox/contentBox.vue';

export default {

    name: 'Register',
    mixins: [validationMixin, validations],
    components: {
        viewWrapper,
        splitedView,
        visitSummary,
        contentBox,
        loginForm,
    },
    data(){
        return {

            patient: {
                name: '',
                surname: '',
                telephone: '',
                email: '',
                password1: '',
                password2: '',
                pesel: '',
            },

            agreements: {
                rules: false,
                consent: false,
                data_confirmation: false,
            },

            loginStep: 'login',

        };
    },
    validations: {

        patient: {
            name: {
                required,
            },
            surname: {
                required,
            },
            telephone: {
                required,
                isValid(telephone) {
                    return this.app__validatePhone(telephone);
                },
            },
            email: {
                required,
                email,
            },
            password1: {
                required,
                minLength(password) {
                    return password.length >= this.$config.RESTRICTIONS.MIN_PASSWORD_LENGTH;
                },
            },
            password2: {
                required,
                minLength(password) {
                    return password.length >= this.$config.RESTRICTIONS.MIN_PASSWORD_LENGTH;
                },
                sameAsPassword: sameAs('password1'),
            },
            pesel: {
                isValidPeselOrEmpty(pesel) {
                    if (!helpers.req(pesel)) return true;
                    if (typeof pesel !== 'string') return false;

                    const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
                    let sum = 0;
                    const controlNumber = parseInt(pesel.substring(10, 11), 10);

                    for (let i = 0; i < weight.length; i++) {
                        sum += (parseInt(pesel.substring(i, i + 1), 10) * weight[i]);
                    }

                    sum %= 10;

                    return (10 - sum) % 10 === controlNumber;
                },
                maxLength: maxLength(11),
                minLength: minLength(11),
            },
        },

        agreements: {
            rules: {
                checked: value => value === true,
            },
            consent: {
                checked: value => value === true,
            },
            data_confirmation: {
                checked: value => value === true,
            },
        },

    },
    methods: {

        ...mapActions('user', ['setPaymentData', 'setProfile']),

        register(){

            this.$v.$touch();
            if (this.$v.$invalid) return;

            const data = new FormData();
            const patientData = {};

            Object.keys(this.patient).forEach(key => {
                patientData[key] = this.patient[key];
            });

            Object.keys(this.selectedTerm.newVisit).forEach(key => {
                if (key !== 'specialties') data.append(key, this.selectedTerm.newVisit[key]);
            });

            data.append('evisit', this.selectedTerm.evisit);
            data.append('note', this.comment);

            if (this.files) {
                this.files.filesNames.forEach(file => {
                    data.append('uploaded_files', file.file);
                });
            }
            if (this.selectedService) data.append('private_service', this.selectedService.id);

            data.append('password', patientData.password2);

            delete patientData.password1;
            delete patientData.password2;

            Object.keys(patientData).forEach(key => {
                data.append(key, patientData[key]);
            });

            this.app__loaderEnqueue(() => {

                return new Promise(resolve => {

                    this.app__getToken().then(token => {

                        this.$http.post('registration/register_and_reserve/', data, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            params: {
                                selected_facility_token: token.accessToken,
                                remote_app: token.remoteApp,
                            },
                        }).then(response => {

                            if (this.selectedTerm.newVisit.visit_kind === 'Prywatna' && response.data.redirect_url) {

                                this.setPaymentData({
                                    url: response.data.redirect_url,
                                    paymentId: response.data.order_id,
                                    price: response.data.price,
                                    useDiscount: response.data.using_loyalty_program_discount,
                                    discountPrice: response.data.price_after_loyalty_program_discount,
                                    state: response.data.state,
                                    date: response.data.date,
                                });

                            }

                            this.manageRoute();
                            resolve();

                        }).catch(error => {

                            resolve();

                            if (typeof error?.response?.data !== 'string'){
                                this.handleApiErrors(error);
                                return;
                            }

                            this.$notifications.push({
                                title: 'Nowa wizyta',
                                content: 'Twoje konto zostało utworzone, jednak nie udało się poprawnie zarezerwować wybranego terminu. '
                                  + 'Aby spróbować ponownie kliknij w link aktywacyjny, który został wysłany na Twoją skrzynkę email',
                                type: 'error',
                            });

                        });

                    });

                });

            });

        },

        handleApiErrors(errors){

            const { response } = errors;
            const messages = response.data;

            console.log(response);

            if (messages.detail){

                Object.values(messages.detail).forEach(message => {
                    this.$notifications.push({
                        title: 'Nowa wizyta',
                        content: message,
                        type: 'error',
                    });
                });

                return;

            }

            if (messages.alerts){

                Object.values(messages.alerts).forEach(message => {
                    this.$notifications.push({
                        title: 'Nowa wizyta',
                        content: message,
                        type: 'error',
                    });
                });

                return;

            }

            const keys = Object.keys(messages);
            const errorsArray = [];

            keys.forEach(key => {
                if (!['step', 'alerts', 'details'].includes(key)){
                    errorsArray.push(messages[key].flat(1));
                }
            });

            const content = [];
            errorsArray.flat(1).forEach(err => {
                content.push(`<li>${err}</li>`);
            });

            this.$notifications.push({
                title: 'Nowa wizyta',
                content: `<ul style="margin-left: 1rem;">${content.join('')}</ul>`,
                type: 'error',
            });

        },

        showLogin(){

            this.$modal.show('login').then(result => {

                if (!result.state) return;

                if (this.profiles && this.profiles.length === 1) this.setProfile(this.profiles[0]);
                this.manageRoute();

            });

        },

        openAgreementModal(){

            this.$modal.show('facility-agreement');

        },

        manageRoute(){

            if (this.user){
                if (!this.selectedProfile){
                    this.$router.push('/profiles');
                    return;
                }
            }

            this.$router.push('/finalization');

        },

        rodoInfoURL() {
            const url = `${process.env.VUE_APP_API}facilities/${this.selectedTerm.department.facility_id}-${this.selectedTerm.app.name}/patient_rodo_information_pdf/`;
            this.app__getToken().then(token => {

                this.$http.get(url, {
                    headers: {
                        'Content-Type': 'Application/json',
                    },
                    params: {
                        selected_facility_token: token.accessToken,
                        remote_app: token.remoteApp,
                    },
                    responseType: 'arraybuffer',
                }).then(response => {
                    const blob = new Blob([response.data], { type:'application/pdf' });
                    window.open(window.URL.createObjectURL(blob), '_blank');
                });
            });

        },

    },
    computed: {

        ...mapState('user', ['user', 'selectedProfile', 'profiles', 'comment', 'files']),
        ...mapState('global', ['selectedTerm', 'selectedService']),

        appHost() {
            return process.env.VUE_APP_HOST;
        },

        facilityData(){
            const { department } = this.selectedTerm;
            return `${department.facility_name}, ${department.address}`;
        },

    },

};

</script>

<style lang="scss" src="./Register.scss" />
