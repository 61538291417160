<template>
    <div class="func-login-form">
        <div class="func-login-form__logo">
            <img src="~@/assets/images/logo/logo-white.svg" />
        </div>
        <div v-if="step === 'login'" class="func-login-form__content">

            <div class="input-row input-row--password" :class="{'input-row--error': $v.credentials.username.$error}">
                <div class="input-row__input">
                    <img src="~@/assets/icons/user_grey.svg" />
                    <input type="email" class="utils-input" placeholder="Login lub email" v-model="credentials.username"/>
                </div>
                <div class="input-row__validations">
                    <small v-if="!$v.credentials.username.required">To pole jest wymagane.</small>
                </div>
            </div>

            <div class="input-row input-row--password" :class="{'input-row--error': $v.credentials.password.$error}">
                <div class="input-row__input">
                    <img src="~@/assets/icons/password.svg" />
                    <input type="password" class="utils-input" placeholder="Hasło" v-model="credentials.password"/>
                </div>
                <div class="input-row__validations">
                    <small v-if="!$v.credentials.password.required">To pole jest wymagane.</small>
                </div>
            </div>

            <div class="input-row input-row--info">
                <p>Nie pamiętasz hasła? <span @click="step = 'remind'">Odzyskaj hasło</span></p>
            </div>

        </div>
        <div v-if="step === 'remind'" class="func-login-form__content">

            <h4>Podaj adres e-mail, na który wysłane zostaną instrukcje.</h4>

            <div class="input-row input-row--password" :class="{'input-row--error': $v.remind.email.$error}">
                <div class="input-row__input">
                    <img src="~@/assets/icons/user_grey.svg" />
                    <input type="email" class="utils-input" placeholder="Email" v-model="remind.email"/>
                </div>
                <div class="input-row__validations">
                    <small v-if="!$v.remind.email.required">To pole jest wymagane.</small>
                    <small v-if="!$v.remind.email.email">Adres email jest nie prawidłowy.</small>
                </div>
            </div>

            <div class="input-row input-row--info">
                <p v-if="step === 'remind'" ><span @click="step = 'login'">Wróć do logowania</span></p>
            </div>

        </div>
        <div class="func-login-form__footer">
            <btn type="next" v-if="step === 'login'" @click="login()">ZALOGUJ SIĘ</btn>
            <btn type="next" v-if="step === 'remind'" @click="remindPassword()">WYŚLIJ E-MAIL</btn>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
    
    name: 'loginForm',
    mixins: [validationMixin],
    data(){
        return {

            step: 'login',

            credentials: {
                username: '',
                password: '',
            },

            remind: {
                email: '',
            },

        };
    },
    validations: {

        credentials: {
            username: { required },
            password: { required },
        },

        remind: {
            email: { required, email },
        },

    },
    methods: {

        ...mapActions('user', ['setProfiles', 'setAccounts', 'setUser']),

        login(){

            this.$v.credentials.$touch();
            if (this.$v.credentials.$invalid) return;

            const loader = this.app__setLoader();

            const formData = new FormData();
            formData.append('username', this.credentials.username);
            formData.append('password', this.credentials.password);
            formData.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);
            formData.append('client_id', 'patient_plugin');
            formData.append('grant_type', 'password');

            this.$http.post(`${process.env.VUE_APP_HOST}o/token/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(async response => {

                this.app__setLocalStorage('mydr-token', response.data, (response.data.expires_in / 60 / 60));

                const promises = [this.getProfiles(), this.getUser(), this.getAccounts()];

                await Promise.all(promises).then(() => {
                    this.clearForms();
                    this.$emit('logged');
                });

            }).catch(error => {

                if (error.response && [400, 401].includes(error.response.status)) {
                    this.$notifications.push({
                        title: 'Przesyłanie plików',
                        content: 'Błędne dane logowania. Prosimy spróbuj ponownie.',
                        type: 'error',
                    });
                    return;
                }

                this.app__handleHttpFailureResponse(error);

            }).finally(() => {
                this.app__loaderDequeue(loader);
            });

        },

        getProfiles(){

            return new Promise((resolve, reject) => {

                this.app__getToken('mydr-token').then(token => {

                    this.$http.get('profile/', {
                        headers: {
                            'Content-Type': 'Application/json',
                            Authorization: `${token.token_type} ${token.access_token}`,
                        },
                    }).then(response => {

                        this.setProfiles(response.data);
                        resolve(response);

                    }).catch(error => {
                        reject(error);
                    });

                }).catch(error => {
                    reject(error);
                });

            });

        },

        getUser(){

            return new Promise((resolve, reject) => {

                this.app__getToken('mydr-token').then(token => {

                    this.$http.get('self/data/', {
                        headers: {
                            'Content-Type': 'Application/json',
                            Authorization: `${token.token_type} ${token.access_token}`,
                        },
                    }).then(response => {

                        this.setUser(response.data);
                        resolve(response);

                    }).catch(error => {
                        reject(error);
                    });

                }).catch(error => {
                    reject(error);
                });

            });

        },

        getAccounts(){

            return new Promise((resolve, reject) => {

                this.app__getToken('mydr-token').then(token => {

                    this.$http.get('remote_account/', {
                        headers: {
                            'Content-Type': 'Application/json',
                            Authorization: `${token.token_type} ${token.access_token}`,
                        },
                        params: {
                            selected_facility_token: this.remoteAppData.accessToken,
                            remote_app: this.remoteAppData.remoteApp,
                        },
                    }).then(response => {

                        this.setAccounts(response.data);
                        resolve(response);

                    }).catch(error => {
                        reject(error);
                    });

                }).catch(error => {
                    reject(error);
                });

            });

        },

        remindPassword(){

            this.$v.remind.$touch();
            if (this.$v.remind.$invalid) return;

            this.app__loaderEnqueue(() => { 
            
                return new Promise(resolve => {

                    const data = {
                        email: this.remind.email,
                    };

                    this.$http.post(`password_reset/send_email/`, data, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(() => {

                        this.$notifications.push({
                            title: 'Przesyłanie plików',
                            content: 'Na podany adres e-mail wysłane zostały dalsze instrukcje.',
                            type: 'success',
                        });
                        this.step = 'login';
                        this.clearForms();

                    }).catch(() => {
                        // this.app__handleHttpFailureResponse(error);
                    }).finally(() => {
                        resolve();
                    });

                });

            });

        },

        clearForms(){

            this.credentials.username = '';
            this.credentials.password = '';
            this.remind.email = '';
            this.$v.$reset();

        },

    },
    computed:{
        ...mapState('global', ['remoteAppData']),
    },

};

</script>

<style lang="scss" src="./loginForm.scss" />
